import React from 'react'
import ContainerEdu from './ContainerEdu'

function Education() {
    return (
        <div id="education" >
            <ContainerEdu />
        </div>
    )
}

export default Education
